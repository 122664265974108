import { Button, Input, Modal, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

function CreateColumn({
  createColumnType,
  saveCreateColumn,
  saveUpdateColumn,
  deleteColumn,
  editColumnDetails,
  setEditColumnDetails,
  columnName,
  setColumnName,
  columnDescription,
  setColumnDescription,
  columnType,
  setColumnType,
  columnkey,
  setColumnkey,
  procoreColumnsInfo,
  undeletableColumns,
  source,
  setSource,
  columnsInfo,
  columnFormula,
  setColumnFormula,
  formulaRows,
  setFormulaRows,
  originalFormulaRows,
  setOriginalFormulaRows,
}) {
  const { Option } = Select;
  const { confirm } = Modal;

  const [formula, setFormula] = useState("");

  const convertFormulaIntoRows = (formulaString) => {
    if (!formulaString) return [];

    // Split the formula on + or - while keeping the operators
    const parts = formulaString.split(/([+-])/);

    // First part is always the first column
    const rows = [{ operator: "", columnKey: parts[0] }];

    // Process the remaining parts in pairs (operator and value)
    for (let i = 1; i < parts.length; i += 2) {
      if (i + 1 < parts.length) {
        rows.push({
          operator: parts[i],
          columnKey: parts[i + 1],
        });
      }
    }

    return rows;
  };

  // useEffect to handle existing formula when editing
  useEffect(() => {
    if (columnFormula && columnFormula.length > 0) {
      const parsedRows = convertFormulaIntoRows(columnFormula);
      setFormulaRows(parsedRows);
      setOriginalFormulaRows(parsedRows);
      setFormula(columnFormula);

      // Set the column type based on the first column in the formula
      if (parsedRows[0]?.columnKey && columnsInfo[parsedRows[0].columnKey]) {
        setColumnType(columnsInfo[parsedRows[0].columnKey].type);
      }
    }
  }, [columnFormula, columnsInfo]);

  const getOptions = () => {
    if (createColumnType === "Koncurent Hard coded") {
      return [
        { value: "string", label: "Text" },
        { value: "date", label: "Date" },
        { value: "int", label: "Number" },
      ];
    } else {
      return Object.entries(procoreColumnsInfo).map(([key, value]) => ({
        value: value.procoreKey,
        label: key,
        type: value.type,
      }));
    }
  };

  const calculatedFirstOptions = () => {
    return Object.entries(columnsInfo)
      .filter(([key, value]) => {
        // If columnkey is not provided, perform the standard filter
        if (!columnkey) {
          return value.usedStatus !== "unused" && value.type !== "string";
        }
        // If columnkey is provided, filter based on the specified criteria
        return (
          value.usedStatus !== "unused" &&
          value.type === columnsInfo[columnkey].type &&
          key !== "specRevision" &&
          key !== columnkey // Remove the column itself from the list
        );
      })
      .map(([key, value]) => ({
        value: key,
        label: value.label,
        type: value.type,
      }));
  };

  const calculatedOtherOptions = () => {
    return Object.entries(columnsInfo)
      .filter(
        ([key, value]) =>
          value.usedStatus !== "unused" &&
          value.type !== "string" &&
          value.type !== "date" &&
          key !== "specRevision"
      )
      .map(([key, value]) => ({
        value: key,
        label: value.label,
        type: value.type,
      }));
  };

  const handleKoncurentHardCodedSave = () => {
    let columnDetails = {
      label: columnName,
      description: columnDescription,
      type: columnType,
      editable: createColumnType === "Procore Source" ? "false" : "true",
      usedStatus: "used",
      formula: "",
      source: createColumnType === "Procore Source" ? source : "koncurent",
    };

    setSource("");

    if (columnkey.length === 0) {
      saveCreateColumn(columnDetails);
    } else {
      saveUpdateColumn(columnDetails, columnkey);
    }
  };

  const handleCalculatedSave = () => {
    const formula = formulaRows.reduce((acc, row, idx) => {
      if (idx === 0) return row.columnKey;
      return `${acc}${row.operator}${row.columnKey}`;
    }, "");

    let columnDetails = {
      label: columnName,
      description: columnDescription,
      type: columnType,
      editable: "false",
      usedStatus: "used",
      formula: formula,
      source: "koncurent",
    };

    if (columnkey.length === 0) {
      saveCreateColumn(columnDetails);
      setColumnFormula("");
      resetFormula();
    } else {
      saveUpdateColumn(columnDetails, columnkey);
      setColumnFormula("");
      resetFormula();
    }
  };

  const deleteColumns = () => {
    let columnDetails = {
      label: "",
      description: "",
      hidden: "true",
      type: "",
      editable: "",
      usedStatus: "unused",
      formula: "",
      source: "",
    };

    deleteColumn(columnDetails, columnkey);
  };

  const handleFirstColumnSelect = (value, option) => {
    setColumnType(option?.type);

    let updatedRows;
    if (formulaRows.length === 0) {
      // If this is the first time selecting a column, create initial rows
      updatedRows = [
        { operator: "", columnKey: option?.value },
        { operator: "+", columnKey: "" },
      ];
    } else {
      updatedRows = [...formulaRows];
      updatedRows[0] = { ...updatedRows[0], columnKey: option?.value };
    }

    setFormulaRows(updatedRows);

    const newFormula = updatedRows.reduce((acc, row, idx) => {
      if (idx === 0) return row.columnKey;
      return row.columnKey ? `${acc} ${row.operator} ${row.columnKey}` : acc;
    }, "");

    setFormula(newFormula);
  };

  const resetFormula = () => {
    setColumnType("");
    setSource("koncurent");

    setFormulaRows([]);

    // Update formula string
    setFormula("");
  };

  const handleFormulaChange = (index, type, value, option) => {
    const updatedRows = [...formulaRows];

    if (type === "columnKey") {
      updatedRows[index] = {
        ...updatedRows[index],
        columnKey: option?.value || "",
      };
    } else {
      updatedRows[index] = {
        ...updatedRows[index],
        [type]: value,
      };
    }

    setFormulaRows(updatedRows);

    // Update formula string
    const newFormula = updatedRows.reduce((acc, row, idx) => {
      if (idx === 0) return row.columnKey;
      return `${acc} ${row.operator} ${row.columnKey}`;
    }, "");

    setFormula(newFormula);
  };

  const handleAddRow = () => {
    setFormulaRows([...formulaRows, { operator: "+", columnKey: "" }]);
  };

  const showDeleteConfirm = (columnkey, deleteColumns) => {
    confirm({
      title: "Are you sure you want to delete this column?",
      content: (
        <div>
          <p>
            You are about to delete the column: <strong>{columnName}</strong>.
            <br />
            This action is permanent and will delete all data for that column.
            <br />
            The action will be done after saving the view.
          </p>
        </div>
      ),
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      width: 600,
      onOk() {
        deleteColumns(columnkey);
      },
      onCancel() {},
    });
  };

  const unEditableColumns = ["projectedDeliveryDate"];

  const unDeletableColumns = Object.keys(undeletableColumns);

  const getReasonForColumn = (columnName) => {
    const reason = undeletableColumns[columnName];

    if (Array.isArray(reason)) {
      if (reason.includes("Koncurent original column")) {
        return "Original Koncurent columns cannot be deleted.";
      } else {
        return "Can't be deleted because it's used in other columns' formulas.";
      }
    }
  };

  return (
    <div className=" d-flex flex-column gap-2">
      <div className=" d-flex justify-content-center gap-2 w-100">
        <div>
          <label htmlFor="columnName">Column Name</label>
          <Input
            value={columnName}
            placeholder="Column Name"
            onChange={(e) => setColumnName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="columnDescription">Column Description</label>
          <Input
            value={columnDescription}
            placeholder="Column Description"
            onChange={(e) => setColumnDescription(e.target.value)}
          />
        </div>
      </div>
      <div className=" d-flex justify-content-center">
        {createColumnType !== "Calculated Column" ? (
          <div className=" d-flex flex-column justify-content-center">
            <label htmlFor="columnTypeSelect">Column Type</label>
            <Select
              id="columnTypeSelect"
              style={{ width: 450 }}
              placeholder="Select column type"
              showSearch
              disabled={columnkey}
              onSearch={true}
              onChange={(value, option) => {
                if (createColumnType === "Koncurent Hard coded") {
                  setColumnType(value);
                } else {
                  setColumnType(option?.type);
                  setSource(
                    createColumnType === "Procore Source" ? value : "koncurent"
                  );
                }
              }}
              value={
                createColumnType === "Procore Source" ? source : columnType
              }
              options={getOptions()}
            ></Select>
            <div className=" d-flex mt-3 justify-content-between gap-2">
              <div>
                {columnkey && (
                  <Tooltip title={getReasonForColumn(columnkey)}>
                    <Button
                      type="primary"
                      disabled={unDeletableColumns.includes(columnkey)}
                      onClick={() =>
                        showDeleteConfirm(columnkey, deleteColumns)
                      }
                      danger
                    >
                      Delete
                    </Button>
                  </Tooltip>
                )}
              </div>

              <Button
                type="primary"
                onClick={handleKoncurentHardCodedSave}
                disabled={!columnName || !columnDescription || !columnType}
              >
                Done
              </Button>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column w-100 justify-content-center gap-2">
            <label htmlFor="columnTypeSelect">Create Formula</label>
            <Select
              id="columnTypeSelect"
              placeholder="Select first column"
              showSearch
              onChange={handleFirstColumnSelect}
              value={formulaRows[0]?.columnKey}
              disabled={unEditableColumns.includes(columnkey)}
              options={calculatedFirstOptions()}
            ></Select>
            {formulaRows.map(
              (row, index) =>
                index > 0 && (
                  <div key={index} className="d-flex gap-2">
                    <Select
                      placeholder="+/-"
                      value={row?.operator}
                      onChange={(value) =>
                        handleFormulaChange(index, "operator", value)
                      }
                      disabled={unEditableColumns.includes(columnkey)}
                    >
                      <Option value="+">+</Option>
                      <Option value="-">-</Option>
                    </Select>
                    <Select
                      className="flex-grow-1"
                      placeholder="Select another column"
                      value={formulaRows[index]?.columnKey}
                      onChange={(value, option) =>
                        handleFormulaChange(index, "columnKey", value, option)
                      }
                      disabled={unEditableColumns.includes(columnkey)}
                      options={calculatedOtherOptions()}
                    ></Select>

                    {index === formulaRows.length - 1 && (
                      <Button
                        onClick={handleAddRow}
                        disabled={
                          !formulaRows[formulaRows.length - 1].columnKey ||
                          unEditableColumns.includes(columnkey)
                        }
                      >
                        +
                      </Button>
                    )}
                  </div>
                )
            )}
            {/* FOR TESTING PURPOSES */}
            {/* {formula && (
              <div>
                <label>Current Formula:</label>
                <div>{formula}</div>
              </div>
            )} */}

            <div className=" d-flex justify-content-between gap-2 mt-2">
              <div>
                {columnkey && (
                  <Tooltip title={getReasonForColumn(columnkey)}>
                    <Button
                      type="primary"
                      disabled={unDeletableColumns.includes(columnkey)}
                      onClick={() =>
                        showDeleteConfirm(columnkey, deleteColumns)
                      }
                      danger
                    >
                      Delete
                    </Button>
                  </Tooltip>
                )}
              </div>

              <div className=" d-flex gap-2">
                {formulaRows.length > 1 && (
                  <Button
                    onClick={resetFormula}
                    disabled={unEditableColumns.includes(columnkey)}
                  >
                    Reset Formula
                  </Button>
                )}

                <Button
                  type="primary"
                  onClick={handleCalculatedSave}
                  disabled={
                    !columnName ||
                    !columnDescription ||
                    formulaRows.length < 1 ||
                    (formulaRows.length > 1 &&
                      !formulaRows[formulaRows.length - 1].columnKey)
                  }
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateColumn;
