import { Button, Tooltip, Select, Input, Table, Modal, Switch } from "antd";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import { CompanyAdminServices } from "apis/Services/CompanyAdminService";
import styles from "./company-dashboard.module.css";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HolderOutlined } from "@ant-design/icons";
import CreateColumn from "./create-column";
import { ProjectServices } from "apis/Services/ProjectService";

const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const SortableRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

export default function CreateView({
  selectedViewName,
  projectsDropDown,
  viewName,
  setViewName,
  viewDescription,
  setViewDescription,
  setCreateFlag,
  readCompanySchedule,
  editFlag,
  setEditFlag,
  procoreColumnsInfo,
  undeletableColumns,
  projectsInfoArray,
}) {
  let code = localStorage.getItem("token");
  let loginEmail = localStorage.getItem("email");
  let Auth = {
    code,
    loginEmail,
  };

  const { company_id } = useParams();

  const [isLoading, setIsLoading] = useState();
  const [dataSample, setDataSample] = useState([]);
  const [columnsInfo, setColumnsInfo] = useState(selectedViewName.columnsInfo);
  const [originalColumnsInfo, setOriginalColumnsInfo] = useState(
    selectedViewName.columnsInfo
  );
  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [viewsProjects, setViewsProjects] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [createNewColumnModalOpen, setCreateNewColumnModalOpen] =
    useState(false);
  const [changesMadeFlag, setChangesMadeFlag] = useState(false);
  const [oldViewName, setOldViewName] = useState("");

  const [showAllColumns, setShowAllColumns] = useState(true);

  const [createColumnType, setCreateColumnType] = useState("");
  const [createColumnFlag, setCreateColumnFlag] = useState(false);
  const [firstUnusedColumn, setFirstUnusedColumn] = useState("");
  const [editColumnDetails, setEditColumnDetails] = useState({});

  const [columnName, setColumnName] = useState("");

  const [columnDescription, setColumnDescription] = useState("");

  const [columnType, setColumnType] = useState("");

  const [columnkey, setColumnkey] = useState("");

  const [source, setSource] = useState("");

  const [columnFormula, setColumnFormula] = useState("");

  const [formulaRows, setFormulaRows] = useState([]);
  const [originalFormulaRows, setOriginalFormulaRows] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      viewName: viewName,
      viewDescription: viewDescription,
      assignedProjects:
        selectedViewName?.viewProjects?.map(
          (project) => project.split("-")[0]
        ) || assignedProjects,
    },
  });

  const getSampleProcurementLogsViews = (value) => {
    setIsLoading(true);
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      projectID: value,
      viewName: selectedViewName.viewName,
    };
    CompanyAdminServices.getSampleProcurementLogsViews(data)
      .then((res) => {
        setDataSample(res.dataSample);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createProcurementLogsViews = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      viewName: viewName,
      viewDescription: viewDescription,
      columnsInfo: [columnsInfo],
      assignedProjects: assignedProjects,
    };
    CompanyAdminServices.createProcurementLogsViews(data)
      .then((res) => {
        setCreateFlag(false);
        setViewName("");
        readCompanySchedule();

        // Update Database
        // Create an array of promises for each project
        const exportPromises = projectsInfoArray.map((project) => {
          const updateBackEndData = {
            company: company_id,
            project: project.projectID,
            code,
            loginEmail,
            approvalTime: project.approvalTime,
            reviewTime: project.reviewTime,
          };

          // Return the promise from the getExportData call
          return ProjectServices.getExportData(updateBackEndData);
        });

        // Wait for all promises to resolve
        return Promise.all(exportPromises);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const updateProcurementLogsViews = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      oldViewName: oldViewName,
      viewName: viewName,
      viewDescription: viewDescription,
      columnsInfo: [columnsInfo],
      assignedProjects: assignedProjects,
    };
    CompanyAdminServices.updateProcurementLogsViews(data)
      .then((res) => {
        setCreateFlag(false);
        setEditFlag(false);
        readCompanySchedule();

        // Update Database
        // Create an array of promises for each project
        const exportPromises = projectsInfoArray.map((project) => {
          const updateBackEndData = {
            company: company_id,
            project: project.projectID,
            code,
            loginEmail,
            approvalTime: project.approvalTime,
            reviewTime: project.reviewTime,
          };

          // Return the promise from the getExportData call
          return ProjectServices.getExportData(updateBackEndData);
        });

        // Wait for all promises to resolve
        return Promise.all(exportPromises);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const toggleAllVisibility = (show) => {
    setColumnsInfo((prev) => {
      const newColumnsInfo = { ...prev };
      Object.keys(newColumnsInfo).forEach((key) => {
        if (
          ![
            "entryID",
            "specSection",
            "specTitle",
            "specNumber",
            "specRevision",
            "specType",
            "submittalPrepTime",
            "submittalIssued",
            "approvalTime",
            "submittalApprovalDue",
            "submittalReturned",
            "leadTime",
            "dateRequiredOnSite",
            "projectedDeliveryDate",
          ].includes(key) &&
          newColumnsInfo[key].usedStatus !== "unused"
        ) {
          newColumnsInfo[key] = {
            ...newColumnsInfo[key],
            hidden: show ? "false" : "true",
          };
        }
      });
      return newColumnsInfo;
    });
  };

  const handlePreviewProjectsChange = (value) => {
    getSampleProcurementLogsViews(value);
  };

  let options = Object.entries(projectsDropDown).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const dataSource = dataSample.map((item, index) => ({
    key: index.toString(),
    ...Object.keys(item).reduce((acc, key) => {
      if (columnsInfo[key]?.hidden === "false") {
        acc[key] = item[key];
      }
      return acc;
    }, {}),
  }));

  const columns = Object.keys(columnsInfo)
    .filter((key) => columnsInfo[key].hidden === "false")
    .sort((a, b) => columnsInfo[a].index - columnsInfo[b].index)
    .map((key) => ({
      title: columnsInfo[key].label,
      dataIndex: key,
      key: key,
      align: "center",
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
    }));

  useEffect(() => {
    if (options.length > 0 && !initialSetupDone) {
      handlePreviewProjectsChange(options[0].value);
      setInitialSetupDone(true);
    }
  }, [options, initialSetupDone]);

  const viewDetailsColumn = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Column Name",
      dataIndex: "label",
      key: "label",
      align: "left",
      render: (hidden, record) => {
        return (
          <div>
            <Tooltip title="Edit">
              <span
                onClick={() => {
                  setCreateNewColumnModalOpen(true);
                  console.log(record);

                  setCreateColumnType(
                    record.source === "koncurent"
                      ? record.formula !== ""
                        ? "Calculated Column"
                        : "Koncurent Hard coded"
                      : "Procore Source"
                  );
                  setColumnName(record.label);
                  setColumnDescription(record.description);
                  setColumnType(record.type);
                  setColumnkey(record.key);
                  setSource(record.source);
                  setColumnFormula(record.formula);
                }}
              >
                {record.label}
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: (
        <div className=" d-flex flex-row justify-content-between">
          <div className="w-100" style={{ marginLeft: "65px" }}>
            Visibility
          </div>
          <div className="w-25">
            <Tooltip title="Toggle All">
              <Switch
                checked={showAllColumns}
                onChange={(checked) => {
                  setShowAllColumns(checked);
                  toggleAllVisibility(checked);
                }}
              />
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: "Hidden",
      key: "Hidden",
      align: "center",
      render: (hidden, record) => (
        <Switch
          disabled={
            record.key === "specTitle" ||
            record.key === "specNumber" ||
            record.key === "specRevision" ||
            record.key === "specType" ||
            record.key === "submittalPrepTime" ||
            record.key === "submittalIssued" ||
            record.key === "approvalTime" ||
            record.key === "submittalApprovalDue" ||
            record.key === "submittalReturned" ||
            record.key === "leadTime" ||
            record.key === "dateRequiredOnSite" ||
            record.key === "projectedDeliveryDate"
          }
          checked={record.hidden === "false"}
          onChange={() => handleVisibilityToggle(record.key)}
        />
      ),
    },
  ];

  const handleVisibilityToggle = (key) => {
    setColumnsInfo((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        hidden: prev[key].hidden === "false" ? "true" : "false",
      },
    }));
  };

  const handleLabelRename = (newLabel, key) => {
    setColumnsInfo((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        label: newLabel,
      },
    }));
  };

  const handleDescriptionRename = (newDescription, key) => {
    setColumnsInfo((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        description: newDescription,
      },
    }));
  };

  const initialModalDataSource = Object.keys(columnsInfo)
    .filter(
      (key) =>
        key !== "entryID" &&
        key !== "specSection" &&
        key !== "specNumber" &&
        key !== "specTitle" &&
        key !== "specRevision" &&
        columnsInfo[key].usedStatus !== "unused"
    )
    .sort((a, b) => columnsInfo[a].index - columnsInfo[b].index)
    .map((key) => ({
      key,
      ...columnsInfo[key],
    }));

  const [modalDataSource, setModalDataSource] = useState(
    initialModalDataSource
  );

  const handleModalOk = () => {
    setOriginalColumnsInfo(columnsInfo);
    setChangesMadeFlag(true);
    setModalOpen(false);
  };

  const handleModalCancel = () => {
    setColumnsInfo(originalColumnsInfo);
    setModalOpen(false);
  };

  const handleCreateModalCancel = () => {
    setCreateNewColumnModalOpen(false);
    setColumnName("");
    setColumnDescription("");
    setColumnType("");
    setColumnkey("");
    setSource("");
    setColumnFormula("");
    setFormulaRows(originalFormulaRows);

    // Using timeout to keep width of the modal until exit animation is done
    setTimeout(() => {
      setCreateColumnType("");
    }, 200);
  };

  const checkUnusedColumns = () => {
    let unusedCount = 0;
    let firstUnusedKey = "";

    Object.keys(columnsInfo).forEach((key) => {
      if (columnsInfo[key].usedStatus === "unused") {
        unusedCount += 1;

        if (!firstUnusedKey) {
          firstUnusedKey = key;
        }
      }
    });

    if (unusedCount < 21) {
      // Allowing 5 custom columns
      setCreateColumnFlag(true);
    } else {
      setCreateColumnFlag(false);
    }

    setFirstUnusedColumn(firstUnusedKey);
  };

  const saveCreateColumn = (columnDetails) => {
    setCreateNewColumnModalOpen(false);

    setColumnsInfo((prevColumnsInfo) => {
      // Check if firstUnusedColumn exists in columnsInfo
      if (firstUnusedColumn in prevColumnsInfo) {
        // Hold the original index and hidden properties
        const { index, hidden } = prevColumnsInfo[firstUnusedColumn];

        return {
          ...prevColumnsInfo,
          [firstUnusedColumn]: {
            ...prevColumnsInfo[firstUnusedColumn],
            ...columnDetails,
            index,
            hidden,
          },
        };
      }
      return prevColumnsInfo;
    });

    setColumnName("");
    setColumnDescription("");
    setColumnType("");
    setColumnkey("");
    setSource("");

    // Using timeout to keep width of the modal until exit animation is done
    setTimeout(() => {
      setCreateColumnType("");
    }, 200);
  };

  const saveUpdateColumn = (columnDetails, columnkey) => {
    setCreateNewColumnModalOpen(false);

    setColumnsInfo((prevColumnsInfo) => {
      // Check if columnkey exists in columnsInfo
      if (columnkey in prevColumnsInfo) {
        // Hold the original index and hidden properties
        const { index, hidden } = prevColumnsInfo[columnkey];

        return {
          ...prevColumnsInfo,
          [columnkey]: {
            ...prevColumnsInfo[columnkey],
            ...columnDetails,
            index,
            hidden,
          },
        };
      }
      return prevColumnsInfo;
    });

    setColumnName("");
    setColumnDescription("");
    setColumnType("");
    setColumnkey("");
    setSource("");

    // Using timeout to keep width of the modal until exit animation is done
    setTimeout(() => {
      setCreateColumnType("");
    }, 200);
  };

  const deleteColumn = (columnDetails, columnkey) => {
    setCreateNewColumnModalOpen(false);

    setColumnsInfo((prevColumnsInfo) => {
      // Check if columnkey exists in columnsInfo
      if (columnkey in prevColumnsInfo) {
        // Hold the original index and hidden properties
        const { index } = prevColumnsInfo[columnkey];

        return {
          ...prevColumnsInfo,
          [columnkey]: {
            ...prevColumnsInfo[columnkey],
            ...columnDetails,
            index,
          },
        };
      }
      return prevColumnsInfo;
    });

    setColumnName("");
    setColumnDescription("");
    setColumnType("");
    setColumnkey("");
    setSource("");

    // Using timeout to keep width of the modal until exit animation is done
    setTimeout(() => {
      setCreateColumnType("");
    }, 200);
  };

  useEffect(() => {
    if (editFlag) {
      const projectIds = selectedViewName.viewProjects.map(
        (project) => project.split("-")[0]
      );
      setAssignedProjects(projectIds);
      setViewsProjects(projectIds);
      setOldViewName(viewName);
    }
  }, [editFlag, selectedViewName]);

  const onDragEnd = ({ active, over }) => {
    // Check if 'over' is null or undefined, indicating a drop outside the table bug
    if (over) {
      if (active.id !== over.id) {
        setModalDataSource((prev) => {
          const activeIndex = prev.findIndex(
            (record) => record.key === active.id
          );
          const overIndex = prev.findIndex((record) => record.key === over.id);
          const updatedDataSource = arrayMove(prev, activeIndex, overIndex);

          const updatedColumnsInfo = { ...columnsInfo };

          // First, assign fixed indexes
          [
            "entryID",
            "specSection",
            "specTitle",
            "specNumber",
            "specRevision",
          ].forEach((key, idx) => {
            if (updatedColumnsInfo[key]) {
              updatedColumnsInfo[key] = {
                ...updatedColumnsInfo[key],
                index: idx.toString(),
              };
            }
          });

          let newIndex = 5;
          updatedDataSource.forEach((record) => {
            updatedColumnsInfo[record.key] = {
              ...updatedColumnsInfo[record.key],
              index: newIndex.toString(),
            };
            newIndex += 1;
          });

          setColumnsInfo(updatedColumnsInfo);
          return updatedDataSource;
        });
      }
    }
  };

  useEffect(() => {
    const updatedModalDataSource = Object.keys(columnsInfo)
      .filter(
        (key) =>
          key !== "entryID" &&
          key !== "specSection" &&
          key !== "specNumber" &&
          key !== "specRevision" &&
          key !== "specTitle" &&
          columnsInfo[key].usedStatus !== "unused"
      )
      .sort((a, b) => columnsInfo[a].index - columnsInfo[b].index)
      .map((key) => ({
        key,
        ...columnsInfo[key],
      }));

    checkUnusedColumns();

    setModalDataSource(updatedModalDataSource);
  }, [columnsInfo]);

  return (
    <>
      <form
        onSubmit={handleSubmit(
          editFlag ? updateProcurementLogsViews : createProcurementLogsViews
        )}
      >
        <div className="my-4 d-flex justify-content-between">
          <Text>{viewName || "Untitled"}</Text>

          <div className="">
            <Button
              type="ghost"
              onClick={() => {
                setViewName("");
                setViewDescription("");
                setCreateFlag(false);
                setEditFlag(false);
                setChangesMadeFlag(false);
              }}
            >
              Cancel
            </Button>
            {viewName.length < 1 ? (
              <Tooltip title="View Name is a required field">
                <Button type="primary" disabled>
                  Save
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="primary"
                disabled={!isDirty && !changesMadeFlag}
                onClick={() => {
                  editFlag
                    ? updateProcurementLogsViews()
                    : createProcurementLogsViews();
                }}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <div className="my-4 d-flex flex-column justify-content-between">
          <Text className={`${styles.title} mb-2`} dontWrap={false}>
            Information
          </Text>

          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <div className="d-flex gap-4">
              <div className="mb-3">
                <label>
                  View Name <span style={{ color: "red" }}>*</span>
                </label>
                <Controller
                  name="viewName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setViewName(e.target.value);
                      }}
                      placeholder="Untitled"
                    />
                  )}
                />
              </div>
              <div className="mb-3">
                <label>View Description</label>
                <Controller
                  name="viewDescription"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setViewDescription(e.target.value);
                      }}
                      placeholder="Type a description here"
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-25">
              <div className="">
                <label>Assign to Projects</label>
                <Controller
                  name="assignedProjects"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      mode="multiple"
                      allowClear
                      maxTagCount={2}
                      showSearch
                      value={assignedProjects}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{
                        width: "100%",
                      }}
                      placeholder="0 projects selected"
                      onChange={(value) => {
                        field.onChange(value);
                        setAssignedProjects(value);
                      }}
                      options={options}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <hr className=" w-100" />
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <div className="d-flex gap-4">
              <div className="mb-3">
                <Text className={`${styles.title} mb-2`} dontWrap={false}>
                  Column Configuration
                </Text>
                <Button
                  type="primary"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  Configure Columns
                </Button>
              </div>
            </div>
            <div className="w-25">
              <div className="">
                <label>Preview with Project</label>
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select a project"
                  onChange={handlePreviewProjectsChange}
                  defaultValue={
                    options.length > 0 ? options[0].value : undefined
                  }
                  options={options}
                />
              </div>
            </div>
          </div>
          <div className=" w-100 m-x-3 overflow-scroll">
            <Table
              style={{
                maxHeight: 500,
              }}
              scroll={{ x: "max-content" }}
              bordered
              dataSource={dataSource}
              columns={columns}
              loading={isLoading ? { indicator: <LoadingComponent /> } : false}
              pagination={false}
            />
          </div>
        </div>

        <Modal
          // title="Configure Columns"
          centered
          open={modalOpen}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          okText="Done"
          width={900}
        >
          <div className=" d-flex justify-content-between my-4">
            <h5>Configure Columns</h5>
            <Button
              type="primary"
              disabled={createColumnFlag}
              onClick={() => {
                setCreateNewColumnModalOpen(true);
              }}
            >
              Create New Column
            </Button>
          </div>
          <div className=" d-flex">
            <div className=" w-100 ">
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={modalDataSource.map((item) => item.key)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    style={{ maxHeight: 700 }}
                    scroll={{ y: 550 }}
                    bordered
                    dataSource={modalDataSource}
                    columns={viewDetailsColumn}
                    components={{
                      body: {
                        row: SortableRow,
                      },
                    }}
                    pagination={false}
                  />
                </SortableContext>
              </DndContext>
            </div>
          </div>
        </Modal>
        <Modal
          centered
          open={createNewColumnModalOpen}
          onCancel={handleCreateModalCancel}
          footer={null}
          width={createColumnType ? 500 : 900}
        >
          <div className=" d-flex align-items-center mb-2">
            <h5 className="">
              {createColumnType === ""
                ? "Choose Column Type"
                : createColumnType}
            </h5>
          </div>
          {createColumnType === "" ? (
            <div className="d-flex justify-content-between align-items-center gap-2">
              <Button
                size="large"
                className="w-100 d-flex flex-column justify-content-center align-items-center"
                style={{ height: "200px" }}
                onClick={() => {
                  setCreateColumnType("Koncurent Hard coded");
                }}
              >
                {/* <div>🔧</div> */}
                <h5>Koncurent Hard Coded</h5>
              </Button>

              <Button
                size="large"
                className="w-100 d-flex flex-column justify-content-center align-items-center"
                style={{ height: "200px" }}
                onClick={() => {
                  setCreateColumnType("Procore Source");
                }}
              >
                {/* <div>📄</div> */}

                <div>
                  <h5>Source Column</h5>
                </div>
              </Button>
              <Button
                size="large"
                className="w-100 d-flex flex-column justify-content-center align-items-center"
                style={{ height: "200px" }}
                onClick={() => {
                  setCreateColumnType("Calculated Column");
                }}
              >
                {/* <div>🔢</div> */}

                <div>
                  <h5>Calculated Column</h5>
                </div>
              </Button>
            </div>
          ) : (
            <CreateColumn
              createColumnType={createColumnType}
              saveCreateColumn={saveCreateColumn}
              saveUpdateColumn={saveUpdateColumn}
              deleteColumn={deleteColumn}
              editColumnDetails={editColumnDetails}
              setEditColumnDetails={setEditColumnDetails}
              columnName={columnName}
              setColumnName={setColumnName}
              columnDescription={columnDescription}
              setColumnDescription={setColumnDescription}
              columnType={columnType}
              setColumnType={setColumnType}
              columnkey={columnkey}
              setColumnkey={setColumnkey}
              procoreColumnsInfo={procoreColumnsInfo}
              undeletableColumns={undeletableColumns}
              source={source}
              setSource={setSource}
              columnsInfo={columnsInfo}
              columnFormula={columnFormula}
              setColumnFormula={setColumnFormula}
              formulaRows={formulaRows}
              setFormulaRows={setFormulaRows}
              originalFormulaRows={originalFormulaRows}
              setOriginalFormulaRows={setOriginalFormulaRows}
            />
          )}
        </Modal>
      </form>
    </>
  );
}
