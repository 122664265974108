import React, { useState } from "react";
import { addHiddenRows, showHiddenRow } from "redux/project-reducer";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/loading-component";
import { Popover } from "antd";
import SubDivisionTableItem from "./sub-division-table-item";
import Text from "components/text";
import addIcon from "assets/images/add-sub-division-item.svg";
import styles from "./div.module.css";
import { useEffect } from "react";
import { Checkbox } from "antd";

const SubDivisionTable = ({
  collapsed,
  data = [],
  divName,
  subDivisionName,
  setcollapsed,
  name,
  checkedRows,
  setCheckedRows,
}) => {
  const dispatch = useDispatch();
  const { projectsAccessLevelValue, projectDetails, hiddenRows, tableRows } =
    useSelector((state) => state.projectDetails);

  const { unsavedChanges } = useSelector((state) => state.projectDetails);

  const alignedData = projectDetails.viewsInfo.alignedData;

  const [tableVisible, setTableVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(addHiddenRows({ subDivisionName: subDivisionName, data: data }));
    setLoading(true);
    const promises = data?.map(async (items, index) => (
      <SubDivisionTableItem
        data={items}
        key={"sub-div" + index + JSON.stringify(items)}
        divName={divName}
        subDivisionName={subDivisionName}
        rowindex={index}
        toggleRowCheck={toggleRowCheck}
        checkedRows={checkedRows}
      />
    ));

    Promise.all(promises)
      .then(() => {})
      .catch((error) => {})
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 400);
      });
  }, [data]);

  const toggleTableVisibility = (e) => {
    e.stoppropagation();
    setTableVisible((prevState) => !prevState);
  };

  const toggleRowCheck = (entryID) => {
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [entryID]: !prevCheckedRows[entryID],
    }));
  };

  const toggleCheckAll = () => {
    const newCheckedRows = { ...checkedRows };
    let allChecked = true;

    data.forEach((item) => {
      if (item.specSection === subDivisionName) {
        if (!checkedRows[item.entryID]) {
          allChecked = false;
        }
      }
    });

    // data.forEach((item) => {
    //   if (item.specSection === subDivisionName) {
    //     newCheckedRows[item.entryID] = !allChecked;
    //   }
    // });

    data.forEach((item) => {
      if (item.specSection === subDivisionName) {
        if (projectDetails.itemsPendingApprovals.includes(item.entryID)) {
          // Uncheck if entryID is in itemsPendingApprovals
          newCheckedRows[item.entryID] = false;
        } else {
          // Otherwise, toggle based on allChecked
          newCheckedRows[item.entryID] = !allChecked;
        }
      }
    });

    setCheckedRows(newCheckedRows);
  };

  const allChecked = data.every(
    (item) => item.specSection === subDivisionName && checkedRows[item.entryID]
  );

  const noneChecked = data.every(
    (item) => item.specSection === subDivisionName && !checkedRows[item.entryID]
  );

  const indeterminate = !allChecked && !noneChecked;

  useEffect(() => {
    const indeterminate = !allChecked && !noneChecked;
  }, [checkedRows]);

  const popoverText = {
    specResponse: "Showing latest workflow response",
    responsibleContractor: "",
    issueContract: "Date Required on Site - LT - AT - RT = Issue Contract",
    contractIssuedRelease: "Date contract is issued to subcontractor",
    submittalPrepTime:
      "Review Time (Time it takes for the subcontractor to issue submittals as well as your internal time to review prior to submitting)",
    submittalRequired: "Date Required on Site - LT - AT = Submittal Required",
    submittalIssued: "Date submittal is issued in Procore",
    approvalTime:
      "Approval Time (Contractual time provided to the Design Team to review submittals in weeks)",
    submittalApprovalDue: "Date Required on Site - LT = Submittal Approval Due",
    submittalReturned: "Date submittal is returned in Procore",
    leadTime: "Lead Time",
    scheduleID: "",
    projectedDeliveryDate:
      "Contract Issued Release + RT + Submittal Issued + AT + Submittal Returned + LT = Projected Delivery Date",
    subCommittedDeliveryDate: "",
    actualDeliveryDate: "Date material arrives on job site",
  };

  const customColumns = [];

  for (let i = 1; i <= 25; i++) {
    const customKey = `custom${i}`;
    if (alignedData[customKey]?.hidden === "false") {
      customColumns.push(
        <th scope="col" key={customKey}>
          <Popover content={alignedData[customKey]?.description || ""}>
            <div className={`${styles.tableHeadBg}`}>
              <Text className={`${styles.tableHeadText}`} dontWrap={false}>
                {alignedData[customKey]?.label || `Custom ${i}`}
              </Text>
            </div>
          </Popover>
        </th>
      );
    }
  }

  const excludedKeys = [
    "entryID",
    "specSection",
    "specTitle",
    "specNumber",
    "specRevision",
    "specType",
  ];

  // Convert alignedData object to an array, sort by index, and filter out excluded keys and hidden items
  const sortedColumns = Object.keys(alignedData)
    .filter(
      (key) =>
        !excludedKeys.includes(key) && alignedData[key].hidden === "false"
    )
    .sort((a, b) => alignedData[a].index - alignedData[b].index);

  return (
    <div
      className={`${styles.tableContainer} ${
        projectsAccessLevelValue == 1 && styles.notAllowed
      } mt-2 px-2`}
      onClick={(e) => toggleTableVisibility(e)}
    >
      {!tableVisible &&
        (loading ? (
          <div>
            <LoadingComponent />
          </div>
        ) : (
          <>
            <table className={`${styles.table} `}>
              <thead className={`${styles.tableHead}`}>
                <tr>
                  <th
                    scope="col"
                    className="w-50"
                    style={{
                      position: "sticky",
                      left: -10,
                      zIndex: 2,
                      backgroundColor: "white",
                    }}
                  >
                    <div className={`${styles.tableHeadBg} w-100 px-3`}>
                      <Checkbox
                        checked={allChecked && !indeterminate}
                        onChange={toggleCheckAll}
                        indeterminate={indeterminate}
                        disabled={
                          projectsAccessLevelValue < 2 || unsavedChanges
                        }
                      ></Checkbox>
                    </div>
                  </th>

                  <th
                    scope="col"
                    style={{
                      position: "sticky",
                      left: 45,
                      zIndex: 1,
                      backgroundColor: "white",
                    }}
                  >
                    {subDivisionName !== "Unlabeled - Unlabeled" ? (
                      <div
                        className={`d-flex align-items-center ${styles.tableHeadText} ${styles.tableHeadBg} pointer `}
                        style={{
                          width: 250,
                          cursor:
                            projectsAccessLevelValue < 2
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          projectsAccessLevelValue < 2
                            ? null
                            : dispatch(showHiddenRow())
                        }
                      >
                        <img src={addIcon} alt="plus-icon" className="mx-1" />
                        <Text className={`${styles.tableHeadText}`}>
                          Add Item
                        </Text>
                      </div>
                    ) : (
                      <>
                        <div
                          className={`d-flex align-items-center ${styles.tableHeadText} ${styles.tableHeadBg}`}
                          style={{
                            width: 250,
                            backgroundColor: "#F4F4F4",
                          }}
                        >
                          <Text className={`${styles.tableHeadText}`}></Text>
                        </div>
                      </>
                    )}
                  </th>

                  <th scope="col">
                    <div className={`${styles.tableHeadBg}`}>
                      <Text
                        className={`${styles.tableHeadText}`}
                        dontWrap={false}
                      >
                        Submittal Number
                      </Text>
                    </div>
                  </th>

                  <th scope="col">
                    <div className={`${styles.tableHeadBg}`}>
                      <Text
                        className={`${styles.tableHeadText}`}
                        dontWrap={false}
                      >
                        Submittal Revision
                      </Text>
                    </div>
                  </th>

                  <th scope="col">
                    <div className={`${styles.tableHeadBg}`}>
                      <Text
                        className={`${styles.tableHeadText}`}
                        dontWrap={false}
                      >
                        Submittal Type
                      </Text>
                    </div>
                  </th>

                  {sortedColumns.map((key) => (
                    <th scope="col" key={key}>
                      <Popover
                        content={
                          popoverText[key] || alignedData[key].description || ""
                        }
                      >
                        <div
                          className={`${styles.tableHeadBg} ${
                            alignedData[key].label.length < 3 &&
                            alignedData[key].type === "int" &&
                            styles.smallCells
                          }`}
                        >
                          <Text
                            className={`${styles.tableHeadText}`}
                            dontWrap={false}
                          >
                            {alignedData[key].label}
                          </Text>
                        </div>
                      </Popover>
                    </th>
                  ))}

                  <th scope="col">
                    <div className={`${styles.tableHeadBg}`}>
                      <Text
                        className={`${styles.tableHeadText}`}
                        dontWrap={false}
                      >
                        Actions
                      </Text>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "white" }}>
                {tableRows?.map((items, index) => (
                  <SubDivisionTableItem
                    data={items}
                    key={"sub-div" + index + JSON.stringify(items)}
                    divName={divName}
                    subDivisionName={subDivisionName}
                    rowindex={index}
                    toggleRowCheck={toggleRowCheck}
                    checkedRows={checkedRows}
                  />
                ))}
              </tbody>
            </table>
          </>
        ))}
    </div>
  );
};
export default SubDivisionTable;
